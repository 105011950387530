import React, {useState, useEffect, useRef, FormEvent} from 'react';
import styled from 'styled-components';
import {useChatStore} from '../store/ChatStore'; // Путь к вашему store
import {ReactTyped} from 'react-typed';
import {CtaButton} from "../styles/styles";
import {getQuestionsHistory, getQuestionStatus, getRewardsList, sendQuestion} from "../api/api";
import {useRewardStore} from "../store/rewardStore";
import AiControlPanel from "./AiControlPanel";
import AiHistory from "./AiHistory";


const AiQuestion: React.FC = () => {
    // локальные
    const [inputValue, setInputValue] = useState('');
    const [isAnswer, setIsAnswer] = useState(false);
    const [activeBlock, setActiveBlock] = useState<'history' | 'current'>('current');
    const setRewardsToClaim = useRewardStore(state => state.setRewardsToClaim)
    const rewards = useRewardStore(state => state.rewards)


    const {
        aiAnswer, questionStatus, setQuestionStatus,
        questionsHistory, userQuestion, setUserQuestion,
        setAiAnswer, setQuestionsHistory
    } = useChatStore();

    const inputRef = useRef<HTMLInputElement>(null); // Добавляем реф для инпута

    // первично получаем статус и отправляем его в стор
    useEffect(() => {
        const initAi = async () => {
            try {
                const status = await getQuestionStatus();

                const history = await getQuestionsHistory();

                setQuestionsHistory(history);
                if (status.data) {
                    setQuestionStatus(status.data.status);


                    if (status.data.status === 'have_answer') {  // Используйте локальную переменную `status`
                        setIsAnswer(true);
                        setAiAnswer(status.data.answer);
                        setUserQuestion(status.data.u_text);
                    }

                } else {
                    setQuestionStatus('no_questions')
                }

            } catch (error) {
            }
        };
        initAi();
    }, [setQuestionStatus]);


// Логика нажатия на кнопки current history
    const handleBlockClick = (block: 'history' | 'current') => {
        setActiveBlock(block);
    };

    // Логика нажатия на кнопку send
    const handleSend = async (e: any) => {
        e.preventDefault();
        if (inputValue.trim()) {
            const trimmedValue = inputValue.trim();
            setUserQuestion(trimmedValue);
            setInputValue('');
            try {
                const sendQuestionRes = await sendQuestion(trimmedValue)
                if (sendQuestionRes) {
                    setQuestionStatus('in_progress');
                }

            } catch (error: any) {
                console.log(error?.response?.data?.message?.text)
                setAiAnswer(error?.response?.data?.message?.text || 'an Error')
                setQuestionStatus('wrong_question')
            }

        }
    };    // Логика нажатия на AcceptAnswer
    const handleAcceptAnswer = async () => {
            const aiQuestionReward = rewards.filter(reward => reward.type === 'ai_question');
            if (aiQuestionReward) {
                setRewardsToClaim(aiQuestionReward)
                setQuestionStatus('received_reward');  // Забираем награды
                setIsAnswer(false)
                setUserQuestion('')
            }


    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            inputRef.current?.blur(); // Убираем фокус с инпута, чтобы закрыть клавиатуру
            handleSend(event); // Отправляем вопрос
        }
    };


    // рендерим UI ответ в зависимости от полученного статуса
    const renderResponse = () => {
        switch (questionStatus) {
            case 'no_questions':
                return 'Greetings, lost soul. If you want to get the right answers, ask the right questions.'
            case 'received_reward':
                return 'Greetings, lost soul. If you want to get the right answers, ask the right questions.'
            case 'in_progress':
                return 'Good question, but you need to be patient, come back a little later to get the answer...'
            case 'have_answer':
                return aiAnswer; // Выводим ответ, когда он получен
            case 'wrong_question':
                return aiAnswer; // Выводим ответ, когда он получен
            default:
                return aiAnswer
        }
    };

    return (
        <Container>
            {activeBlock === 'current' && (
                <>
                    <MessageWindow>
                        <AiMassageWindow>
                            <AiMessageHeader>AI God:</AiMessageHeader>
                            <Message
                                strings={[renderResponse()]}
                                typeSpeed={5}
                                cursorChar={''}
                                onStringTyped={async () => {

                                }}
                            />
                        </AiMassageWindow>

                        <UserMassageWindow isMessage={!!userQuestion}>
                            <AiMessageHeader>You</AiMessageHeader>
                            <Message
                                strings={[userQuestion]}
                                typeSpeed={5}
                                cursorChar={''}
                            />
                        </UserMassageWindow>
                        {/* <AnimatedBallsWrapper>
                            <AnimatedBalls color={"#FFFFFF"} inverted={true} />
                        </AnimatedBallsWrapper> */}

                    </MessageWindow>

                    {isAnswer && (
                        <AcceptAnswerButton onClick={handleAcceptAnswer}> ACCEPT ANSWER</AcceptAnswerButton>
                    )}
                    <AiControlPanel activeBlock={activeBlock} handleBlockClick={handleBlockClick}/>

                    <InputContainer onSubmit={handleSend}>
                        <Input
                            type="text"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Input Question to AI God"
                            enterKeyHint="send"
                        />
                        <SendButton
                            disabled={questionStatus !== 'received_reward' && questionStatus !== 'no_questions' && questionStatus !== 'wrong_question'}
                        >
                            Send
                        </SendButton>
                    </InputContainer>
                </>

            )}

            {activeBlock === 'history' && (
                <>
                    <AiControlPanel activeBlock={activeBlock} handleBlockClick={handleBlockClick}/>
                    <AiHistory questionsHistory={questionsHistory}/>
                </>
            )}


        </Container>

    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 93%;
    justify-content: end;
`;

const AiMessageHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 0 1vh;
    color: #A7A7A7;
    font-size: 10px;
`;
const AnimatedBallsWrapper = styled.div`
`;

const MessageWindow = styled.div`
    height: 35vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 10px;
    z-index: 2;
`;
const AiMassageWindow = styled.div`
    box-sizing: border-box;
    background: #000000;
    border: 1px solid #69FF93;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #69FF93;
    min-height: 80px;


`;
const UserMassageWindow = styled.div<{ isMessage: boolean }>`
    background: #000000;
    position: relative;
    border: ${({isMessage}) => (isMessage ? '1px solid #FFFFFF' : 'none')};
    opacity: ${({isMessage}) => (isMessage ? '1' : '0')};
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    flex-direction: column;
    padding-bottom: 1vh;
`;

const Message = styled(ReactTyped)`
    padding: 1vh 2vh;
    width: 100%;
    height: 100%;

`;

const InputContainer = styled.form`
    margin-top: 1vh;
    display: flex;
    align-items: center;
`;

const Input = styled.input`
    flex: 1;
    padding: 10px;

    /* Group 1135620 */

    box-sizing: border-box;

    width: 293px;
    height: 42px;

    background: #000000;
    border: 1px solid #25362E;
    border-radius: 8px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #A7A7A7;

`;

const SendButton = styled(CtaButton)`
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;

    /* ENTER 2EDEN Button */

    background: #FFFFFF;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
`;


const AcceptAnswerButton = styled(CtaButton)`
    margin-bottom: 1vh;
`;

export default AiQuestion;
