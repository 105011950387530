import {create} from "zustand";
import {Task} from "./tasksStore";


interface RankVisibility {
    id: number;
    visibility: {
        id: number;
        type: string;
    };
}

interface Rank {
    id: number;
    league: number;
    name: string;
    press_force: number;
    max_energy: number;
    energy_per_sec: number;
    price: number;
    rank_visibilities: RankVisibility[];
}

interface Activity {
    id: number;
    reg_date: string;
    last_login_date: string;
    last_daily_reward: string;
    last_sync_energy: string;
    next_inspiration: string;
    next_mining: string;
    is_active_mining: boolean;
    active_days: number;
}

interface Reward {
    id: number;
    type_name: string;
    coins: number;
    inspirations: number;
    replenishments: number;
}

interface Stats {
    id: number;
    coins: number;
    energy: number;
    earned_week_coins: number;
    invited_friends: number;
    inspirations: number;
    replenishments: number;
}

export interface User {
    id: number;
    rank: Rank;
    referrer: string | null;
    country: string;
    referral_code: string;
    activity: Activity;
    leader_place: number | null;
    rewards: Reward[];
    stats: Stats;
    user_tasks: Task[];
    leads: User[];
    region_changes: number

    first_name: string,
    last_name: string,
    username: string,
    base64_avatar: string
    language_code: string,
    allows_write_to_pm: boolean
}


interface StoreState {
    initData: string | null;
    user: User | null;
    setInitData: (initData: string) => void;
}

const INITIAL_DATA_STRING = ``;

export const initDataStore = create<StoreState>((set, get) => ({
    initData: INITIAL_DATA_STRING,
    user: null,
    setInitData: (initData: string) => set({initData})
}));
//query_id=AAGdJCdOAgAAAJ0kJ04qzMwI&user=%7B%22id%22%3A5606155421%2C%22first_name%22%3A%22Anna%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22sobored19%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1728123438&hash=5020a03cfd02dd7635c74449e271041b3f5091c8792a4755d83fc42800826b8f