import axios from 'axios';
import {initDataStore, User} from "../store/initDataStore";
import {toast} from "react-toastify";
import {handleApiError} from "../utils/handleApiError";
import {Reward} from "../store/rewardStore";
import {LeaderboardResponse} from "../store/leaderbordStore";

// const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = 'http://147.45.232.159:180/api';
const API_URL = 'https://api.2eden.ai/api';

const getHeaders = () => {
    const initData = initDataStore.getState().initData;
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Telegram-Init-Data': initData,
    }
}
export const getUserProfile = async (): Promise<User> => {
    // Получаем актуальное состояние из хранилища
    const headers = getHeaders();

    const response = await axios.get(`${API_URL}/user/profile`, {headers});

    const responseData = response.data.body
        ? JSON.parse(response.data.body)
        : response.data.data;

    return responseData;
};

export const syncClicks = async (clicks: number) => {
    // Получаем актуальное состояние из хранилища
    const headers = getHeaders()


    try {
        const response = await axios.patch(`${API_URL}/game_actions/sync_clicks`,
            {clicks},
            {headers: headers}
        );

        console.log('Успешная синхронизация:', response.data);
    } catch (error: any) {
        handleApiError(error)
    }
};

export const startMining = async () => {
    const headers = getHeaders()

    try {
        // Важно: передача данных в POST должна быть в первом аргументе, а заголовки во втором.
        const response = await axios.post(`${API_URL}/mining/start`, {}, {headers});
        console.log('Успешный старт майнинга:', response.data);
        return response.data;
    } catch (error: any) {
        handleApiError(error)
    }
};

export const claimMining = async () => {
    const headers = getHeaders()

    try {
        // Важно: передача данных в POST должна быть в первом аргументе, а заголовки во втором.
        const response = await axios.post(`${API_URL}/mining/claim`, {}, {headers});
        console.log('Успешный клейм майнинга:', response.data);
        return response.data;
    } catch (error: any) {
        handleApiError(error)
    }
};

export const activateReplenishment = async () => {
    const headers = getHeaders()

    try {
        // Важно: передача данных в POST должна быть в первом аргументе, а заголовки во втором.
        const response = await axios.post(`${API_URL}/game_actions/use_replenishment`, {}, {headers});
        console.log('Успешное использование буста replenishment', response.data);
        return response.data;
    } catch (error: any) {
        handleApiError(error)
    }
};

export const syncInspirationClicks = async (clicks: number) => {
    // Получаем актуальное состояние из хранилища
    const headers = getHeaders()


    try {
        const response = await axios.patch(`${API_URL}/game_actions/sync_inspiration`,
            {clicks},
            {headers: headers}
        );

        console.log('Успешная синхронизация:', response.data);
    } catch (error: any) {
        handleApiError(error)
    }
};

export const promoteRank = async () => {
    // Получаем актуальное состояние из хранилища
    const headers = getHeaders()

    try {
        const response = await axios.patch(`${API_URL}/user/rank`,
            {},
            {headers: headers}
        );


        console.log('Успешный promote:', response.data);
        return response.data
    } catch (error: any) {
        handleApiError(error)
    }
};

export const getRewardsList = async (): Promise<Reward[]> => {
    const headers = getHeaders();

    const { data } = await axios.get(`${API_URL}/reward/list`, { headers });

    // if (data.status_code === 404) {
    //     console.log('da 404');
    //     throw new Error('Resource not found');
    // }

    return data.body
        ? JSON.parse(data.body).data.rewards
        : data.data.rewards;
};

export const claimReward = async (rewardId: number) => {
    // Получаем актуальное состояние из хранилища
    const headers = getHeaders()

    console.log(headers);

    try {
        const response = await axios.post(`${API_URL}/reward/receive`, {reward_id: rewardId},
            {headers: headers}
        );

    } catch (error: any) {
        handleApiError(error)
    }
};
// export const claimRewards = async () => {
//     // Получаем актуальное состояние из хранилища
//     const headers = getHeaders()
//
//     console.log(headers);
//
//     try {
//         const response = await axios.post(`${API_URL}/reward/receive/all`, {},
//             {headers: headers}
//         );
//
//     } catch (error: any) {
//         handleApiError(error)
//     }
// };

export const getLeaderboard = async (): Promise<LeaderboardResponse> => {
    const headers = getHeaders();

    const response = await axios.get<LeaderboardResponse>(`${API_URL}/user/leaderboard`, { headers });

    const responseData = response.data 

    return responseData;
};
export const getPastLeaderboard = async (): Promise<[LeaderboardResponse | null, Error | null]> => {
    // Получаем актуальное состояние из хранилища
    const headers = getHeaders()


    try {
        const response = await axios.get(`${API_URL}/user/leaderboard/past`, {headers: headers});

        if (response.data) {
            console.log(response.data)
        }

        const responseData = response.data.body
            ? JSON.parse(response.data.body)
            : response.data

        return [responseData, null]
    } catch (error: any) {
        handleApiError(error)
        return [null, error]
    }
};


export const getQuestionsHistory = async () => {
    // Получаем актуальное состояние из хранилища
    const headers = getHeaders()

    try {
        const response = await axios.get(`${API_URL}/questions/history`, {headers: headers});

        if (response.data) {
            const responseData = response.data.body
                ? JSON.parse(response.data.body).data.questions
                : response.data.data.questions;
            return responseData
        }


    } catch (error: any) {
        handleApiError(error)
    }
};
export const getQuestionStatus = async () => {
    // Получаем актуальное состояние из хранилища
    const headers = getHeaders()


    try {
        const response = await axios.get(`${API_URL}/questions/last_question`, {headers: headers});

        if (response.data) {

        }

        const responseData = response.data.body
            ? JSON.parse(response.data.body)
            : response.data;

        return responseData
    } catch (error: any) {
        handleApiError(error)
    }
};
export const sendQuestion = async (question: string) => {
    const headers = getHeaders(); // Функция получения заголовков

    // Корректно передаем заголовки третьим аргументом
    const response = await axios.post(
        `${API_URL}/questions/ask?question=${encodeURIComponent(question)}`,
        {},
        {headers} // Заголовки передаем как опции запроса
    );

    return response.data;
};

export const getTasks = async () => {
    // Получаем актуальное состояние из хранилища
    const headers = getHeaders()

    try {
        const response = await axios.get(`${API_URL}/tasks/`, {headers: headers});

        const responseData = response.data.body
            ? JSON.parse(response.data.body).tasks
            : response.data.tasks;

        return responseData
    } catch (error: any) {
        handleApiError(error)
    }
};

export const startTaskApi = async (task_id: number) => {
    // Получаем актуальное состояние из хранилища
    const headers = getHeaders()

    try {
        const response = await axios.post(`${API_URL}/tasks/${task_id}/start`, {},
            {headers: headers}
        );

        console.log('Su claim rewards:', response.data);
    } catch (error: any) {
        handleApiError(error)
    }
}
export const completeTask = async (task_id: number) => {
    // Получаем актуальное состояние из хранилища
    const headers = getHeaders()

    console.log(headers);
    try {
        const response = await axios.post(`${API_URL}/tasks/${task_id}/complete`, {},
            {headers: headers}
        );

        console.log('Su claim rewards:', response.data);
    } catch (error: any) {
        handleApiError(error)
    }
}// Получаем актуальное состояние из хранилища

export const changeRegion = async (country: string) => {
    const headers = getHeaders(); // Функция получения заголовков

    try {
        // Корректно передаем заголовки третьим аргументом
        const response = await axios.post(
            `${API_URL}/user/change_region`,
            {code: country},
            {headers} // Заголовки передаем как опции запроса
        );

        return response.data;
    } catch (error: any) {
        handleApiError(error)
    }
};


