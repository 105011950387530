import React, {useEffect} from 'react';
import styled from 'styled-components';
import coinIcon from '../assets/images/coin-icon.svg'
import BalanceCounter from "./BalanceCounter";
import {useNavigate} from "react-router-dom";
import {GradientButton} from "../styles/styles";
import {useCountryStore} from "../store/countryStore";
import walletIcon from '../assets/icons/wallet-icon.svg'


const ControlPanel: React.FC = () => {
    const navigate = useNavigate();
    const flag = useCountryStore(state => state.flag)


    return (
        <Container>
            <IconsWrapper onClick={() => navigate('/change-country')}>
                <ControlPanelButton>{flag}</ControlPanelButton>
                {/*<ControlPanelButton> EN </ControlPanelButton>*/}
            </IconsWrapper>

            <Center>
                <Image src={coinIcon} alt=""/>
                <BalanceCounter/>
            </Center>

            <IconsWrapper>
                <ControlPanelButton><img src={walletIcon} alt=""/></ControlPanelButton>
            </IconsWrapper>
        </Container>
    );
};

export const ControlPanelButton = styled(GradientButton)`
    width: 32px;
    height: 32px;
    min-height: 32px;
    font-size: 1.491vh; /* Убедитесь, что шрифт достаточно велик */
    display: flex;
    align-items: center;
    justify-content: center; /* Центрируем эмодзи */
    font-weight: inherit;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1.118vh;
    height: 4vh;
`;

const Center = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Отступ между картинкой и числом */
`;

const IconsWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

// Стили для изображений
const Image = styled.img`

`;

// Стили для большого числа

export default ControlPanel;
