import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { initDataStore, User } from '../store/initDataStore';
import Loader from '../components/helpers/Loader';
import { useQuery } from '@tanstack/react-query';
import { getUserProfile } from '../api/api';
import { useUiStore } from '../store/userStore';

const SecureRoute: React.FC = () => {
    console.log('SecureRoute');
    const currentInitData = initDataStore.getState().initData;
    const [initDataAvailable, setInitDataAvailable] = useState(!!currentInitData);
    const navigate = useNavigate();

    useEffect(() => {
        const telegramWebApp = window.Telegram?.WebApp;

        // Если initData уже есть, ничего не делаем
        if (!currentInitData && telegramWebApp) {
            const initData = telegramWebApp.initData;

            if (initData) {
                console.log("initData", initData);
                setInitDataAvailable(true);
                // Сохраняем initData в сторе
                initDataStore.getState().setInitData(initData);

                // Настройка Telegram WebApp
                telegramWebApp.disableVerticalSwipes();
                telegramWebApp.expand();

                const TgBackButton = telegramWebApp.BackButton;
                TgBackButton.show();
                TgBackButton.onClick(() => navigate(-1));
            } else {
                console.error('initData is not available.');
            }
        } else if (!telegramWebApp) {
            console.error('Telegram WebApp is not available.');
        }
    }, [navigate]);

    // Запрашиваем профиль пользователя, если initData доступен
    const { data: user, isLoading } = useQuery({
        queryKey: ['user'],
        queryFn: getUserProfile,
        enabled: initDataAvailable,

    });
    useEffect(() => {
        if (user) {
            console.log('User data:', user);
            // Обновляем данные пользователя в сторах
            useUiStore.getState().updateUiUserData(user);
        }
    }, [user]);

    if (isLoading) {
        return <Loader />;
    }

    return <Outlet />;
};

export default SecureRoute;
